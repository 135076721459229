import"core-js/modules/es.array.map.js";/**
 * Poll normalizer:
 * Converts API polls into our internal format.
 * @see {@link https://docs.joinmastodon.org/entities/poll/}
 */import escapeTextContentForBrowser from'escape-html';import{Map as ImmutableMap,List as ImmutableList,Record as ImmutableRecord,fromJS}from'immutable';import emojify from'soapbox/features/emoji';import{normalizeEmoji}from'soapbox/normalizers/emoji';import{makeEmojiMap}from'soapbox/utils/normalizers';// https://docs.joinmastodon.org/entities/poll/
export var PollRecord=ImmutableRecord({emojis:ImmutableList(),expired:false,expires_at:'',id:'',multiple:false,options:ImmutableList(),voters_count:0,votes_count:0,own_votes:null,voted:false,pleroma:ImmutableMap()});// Sub-entity of Poll
export var PollOptionRecord=ImmutableRecord({title:'',votes_count:0,// Internal fields
title_emojified:''});// Normalize emojis
var normalizeEmojis=function normalizeEmojis(entity){return entity.update('emojis',ImmutableList(),function(emojis){return emojis.map(normalizeEmoji);});};var normalizePollOption=function normalizePollOption(option){var emojis=arguments.length>1&&arguments[1]!==undefined?arguments[1]:ImmutableList();var emojiMap=makeEmojiMap(emojis);if(typeof option==='string'){var _titleEmojified=emojify(escapeTextContentForBrowser(option),emojiMap);return PollOptionRecord({title:option,title_emojified:_titleEmojified});}var titleEmojified=emojify(escapeTextContentForBrowser(option.get('title')),emojiMap);return PollOptionRecord(option.set('title_emojified',titleEmojified));};// Normalize poll options
var normalizePollOptions=function normalizePollOptions(poll){var emojis=poll.get('emojis');return poll.update('options',function(options){return options.map(function(option){return normalizePollOption(option,emojis);});});};// Normalize own_votes to `null` if empty (like Mastodon)
var normalizePollOwnVotes=function normalizePollOwnVotes(poll){return poll.update('own_votes',function(ownVotes){return(ownVotes===null||ownVotes===void 0?void 0:ownVotes.size)>0?ownVotes:null;});};// Whether the user voted in the poll
var normalizePollVoted=function normalizePollVoted(poll){return poll.update('voted',function(voted){var _poll$get;return typeof voted==='boolean'?voted:((_poll$get=poll.get('own_votes'))===null||_poll$get===void 0?void 0:_poll$get.size)>0;});};export var normalizePoll=function normalizePoll(poll){return PollRecord(ImmutableMap(fromJS(poll)).withMutations(function(poll){normalizeEmojis(poll);normalizePollOptions(poll);normalizePollOwnVotes(poll);normalizePollVoted(poll);}));};